$(document).on('turbolinks:load', function () {
    $(".search_summit").keyup(function () {
        $.ajax({
            url: "/summits/",
            type: 'GET',
            dataType: 'script',
            data: {search: $(this).val()}
        });
    });

    $('body').on('change', '.session_partners', () => {
        var summitId = $('.session_partners').data('id');
        var checkedItems = $('input[name="partners"]:checked');
        var values = [];

        checkedItems.each(function () {
            values.push($(this).val());
        });
        $.ajax({
            url: `/summits/${summitId}/filter_sessions`,
            type: 'GET',
            dataType: 'script',
            data: {partners: values}
        });
    });

    $('body').on('change', '.session_categories', () => {
        var summitId = $('.session_categories').data('id');
        var checkedItems = $('input[name="categories"]:checked');
        var values = [];

        checkedItems.each(function () {
            values.push($(this).val());
        });
        $.ajax({
            url: `/summits/${summitId}/filter_sessions`,
            type: 'GET',
            dataType: 'script',
            data: {categories: values}
        });
    });

    $('body').on('change', '.session_tracks', () => {
        var summitId = $('.session_tracks').data('id');
        var checkedItems = $('input[name="tracks"]:checked');
        var values = [];

        checkedItems.each(function () {
            values.push($(this).val());
        });
        $.ajax({
            url: `/summits/${summitId}/filter_sessions`,
            type: 'GET',
            dataType: 'script',
            data: {tracks: values}
        });
    });

    $('.create_summit_step_item').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        var tabData = $(this).data("tab");
        $('.create_summit_step_item').removeClass('active');
        $('.create_summit_tab_container').addClass('d-none');
        $('.create_summit_step_item[data-tab="' + tabData + '"]').addClass('active');
        $('.' + tabData).removeClass('d-none');
    });

    $(document).on('click', "#summit_details_submit_btn", function (event) {
        var inputVal = $('[name="summit[location]"]').val();
        var found = false;
        $('[name="summit[location]"]').autoComplete("node").siblings(".dropdown-menu").find(".dropdown-item").each(function () {
            if ($(this).text() === inputVal) {
                found = true;
            }
        });
        if (!found) {
            event.preventDefault();
            $('[name="summit[location]"]').val('');
            $('[name="summit[location]"]').prop('required', true);
            $('[name="summit[location]"]').focus();
        }
    });

    $(document).off('click', '.create_job_save_btn').on('click', '.create_job_save_btn', function(e) {
        e.preventDefault();
        var sponsor = $('#partner_id').val();
        if (typeof sponsor !== 'undefined' && sponsor.length === 0){
            $('#timetable_session_sponsors_error').text('Please select at least one sponsor.');
            $('#timetable_session_sponsors_error').removeClass('hidden');
        }else{
            var form = $(this).closest('form');
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                success: function(response) {
                },
                error: function(xhr, status, error) {
                }
            });
        }
    });

    $(document).ready(function (){
          var element = document.querySelector('[name="summit[description]"]');
          if (element) {
            ClassicEditor
              .create(element)
              .catch(error => {
                console.error('There was a problem initializing the editor.', error);
              });
          }

        var fields = [
            {
                selectors: ['#summit_summit_time', '#summit_review_deadline'],
                isRangePicker: true
            },
            {
                selectors: ['#summit_deadline', '#summit_acceptance_deadline'],
                isRangePicker: false
            }
        ];
        fields.forEach(function (fieldSet) {
            fieldSet.selectors.forEach(function (selector) {
                var $field = $(selector);

                if ($field.length) {
                    var deadlineValue = $field.val();
                    var startDate, endDate, formattedDeadline, formattedDeadlineEnd;

                    if (deadlineValue) {
                        if (fieldSet.isRangePicker) {
                            var deadlineParts = deadlineValue.split(' - ');
                            startDate = moment(deadlineParts[0], 'MMM D, YYYY');
                            endDate = moment(deadlineParts[1], 'MMM D, YYYY');
                            formattedDeadline = startDate.format("MMM D, YYYY");
                            formattedDeadlineEnd = endDate.format("MMM D, YYYY");
                        } else {
                            startDate = moment(deadlineValue, 'MMM D, YYYY');
                            formattedDeadline = startDate.format("MMM D, YYYY");
                        }
                    }

                    jquery($field[0]).daterangepicker({
                        startDate: formattedDeadline ? formattedDeadline : moment().startOf('day'),
                        endDate: formattedDeadlineEnd ? formattedDeadlineEnd : moment().add(6, 'days'),
                        singleDatePicker: !fieldSet.isRangePicker,
                        locale: {
                            format: 'MMM D, YYYY'
                        }
                    });
                }
            });
        });
    });

    $('body').on('click', '#add_panelist', function() {
        $('#session_section').addClass('d-none');
        var panelist = $(this).attr("data-panelist");
        $('.panelist_modal').removeClass('d-none');
        $('#panelist_' + panelist).removeClass('d-none');
    });

    $('body').on('click', '#add_session', () => {
        $('.panelist_modal').addClass('d-none')
        $('#session_section').removeClass('d-none')
    });
    $('body').on('change', "#session_time_from", function(e) {
        var timetable_day = $('#timetable_day').val();
        var time_from     = $('#session_time_from').val();

        var formattedDateTime = timetable_day + " " + time_from;
        $('#timetable_session_time_from').val('');
        $('#timetable_session_time_from').val(formattedDateTime);
    });

    $('body').on('change', "#session_time_to", function(e) {
        var timetable_day = $('#timetable_day').val();
        var time_to       = $('#session_time_to').val();

        var formattedDateTime = timetable_day + " " + time_to;
        $('#timetable_session_time_to').val('');
        $('#timetable_session_time_to').val(formattedDateTime);
    });

    // travel and emergency info form search
    $(() => {
        $('#TravelAndEmergencyInfoformSearh').keyup(e => {
          let $this = $(e.currentTarget)
          let authenticity_token = $('meta[name=csrf-token]').attr('content')
          let search = $this.val()

          $('#travel-and-emergency-info-here').load('filter_emergency_contact_and_travel_information', { authenticity_token, search })
        })
    })
    $('body').on('click', '.save_track_btn', function(e) {
        var sponsor = $('#track_sponsor_id').val();
        if (typeof sponsor !== 'undefined' && sponsor.length === 0){
            e.stopPropagation();
            e.preventDefault();
            $('#track_sponsors_error').text('Please select at least one sponsor.');
            $('#track_sponsors_error').removeClass('d-none');
        }
    });
    $(document).ready(function() {
      const sponsorSelect = $('#partner_id');
      const hostRadios = $('.host-radio');

      function toggleSponsorSelection() {
        const selectedHost = $('.host-radio:checked').val();

        if (selectedHost === 'company') {
          sponsorSelect.prop('multiple', false);
        } else {
          sponsorSelect.prop('multiple', true);
        }

        sponsorSelect.selectpicker('destroy');
        sponsorSelect.selectpicker();
      }

      hostRadios.change(toggleSponsorSelection);

      toggleSponsorSelection();
    });

});
